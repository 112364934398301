import React from 'react'
import { Carousel } from "react-responsive-carousel";
import Slide from 'react-reveal/Slide';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { baseurl } from './API';

const CarousalApp = (props) => (
    <div className="featured pgallery">
        <div className="featured_main">
            <Carousel transitionTime={1200} stopOnHover={true} autoPlay={props.cdata.data.length !== 1 ? true : false} showThumbs={false} showStatus={false} showIndicators={false} infiniteLoop={true} interval={3500}>
                {props.cdata.data.map((cardata, index) => {
                    let image = cardata.relationships.field_banner_image.data ? props.cdata.included.filter(x => x.id === cardata.relationships.field_banner_image.data.id)[0].attributes.uri.url : ""
                    return <div key={index} className="carousal-div">
                        <img src={image ? baseurl + image : ""} alt="carousal" />
                        <div className="carousal-text">
                            {cardata.attributes.field_heading_text ?
                                <p>{cardata.attributes.field_heading_text}</p>
                                : ""}
                            {cardata.attributes.field_sub_text ?
                                // <Slide bottom>
                                <p>{cardata.attributes.field_sub_text}</p>
                                // </Slide>
                                : ''}
                        </div>
                    </div>
                })}

            </Carousel>
        </div>
    </div>
)

export default CarousalApp