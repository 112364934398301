import React, { Component } from 'react';
import '../App.css';
import Footerindex from './Footerindex';
import Navbar from './Navbar';
import Axios from 'axios';
import Iframe from 'react-iframe'
import { FacebookProvider, Page } from 'react-facebook';
import Slide from 'react-reveal/Slide';
import ReactHtmlParser from 'react-html-parser';
import { baseurl } from "./API";
import Header from './header';
import NewArrivals from './newArrivals';
import Updates from './updates';
import PGallery from './PGallery';
import CatIcons from './categoryicons';
import SecondFooter from './secondFooter';


class Division extends Component {
    constructor(props) {
        super(props);
        console.log(props, "prosss")
        this.state = {
            sec: 1,
            uuid: props.match.params.uuid,
            divsn: props.match.params.divsn,
            yurl: "",
            api: '',
            tab: this.props.tab,
            indice: 2,
            gallery_add: "",
            vidtext: "",
            cont: ["Trade offers", "Switchgear offers", "Project Offers"],
            enqopen:false
        }
    }

    componentDidMount() {
        this.videocall(this.state.divsn, this.state.uuid)
        this.categortload()
    }

    componentWillReceiveProps(nextProps) {
        this.videocall(nextProps.match.params.divsn, nextProps.match.params.uuid)
        this.newsstrip(nextProps.match.params.uuid)
        this.setState({
            enqopen:false,
            uuid:nextProps.match.params.uuid
        })
    }

    footerset(info){
        this.setState({
            info:info
        })
    }

    brands() {
        Axios(baseurl + '/jsonapi/node/brands?include=field_logo&filter[idFilter][condition][value]=27fca701-5bee-46d1-b49b-7d57fde95975&filter[idFilter][condition][path]=field_division.uuid', {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    brands: res.data.included
                })
                console.log(res.data.included, 'brands')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    categortload() {
        Axios(baseurl + '/jsonapi/taxonomy_term/category?include=field_category_icon', {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    category: res.data
                })
                this.brands()
                console.log(res.data, 'catload')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    videocall(div, uuid) {
        this.topFunction()
        Axios(baseurl + '/jsonapi/block_content/basic?include=field_banner_image&filter[field_video_text]=' + div, {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    video: res.data.data[0].attributes.field_link.uri,
                    bannerimg:res.data.included[0].attributes.uri.url
                })
                this.newsstrip(uuid)
                console.log(res.data, 'vidd')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    newsstrip(uuid) {
        Axios(baseurl + '/jsonapi/node/newsstrip?page[limit]=5&sort=-created&include=field_pdf_file&filter[idFilter][condition][path]=field_division.uuid&filter[idFilter][condition][value]=' + uuid, {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    newsstrip: res.data
                })
                this.apicall(uuid)
                console.log(res.data, 'newws')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    apicall(uuid) {
        Axios(baseurl + '/jsonapi/node/divisions?include=field_gallery&filter[uuid]=' + uuid, {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    api: res.data
                })
                if(res.data.data[0].attributes.title === "Trading Division")
                {this.newarrivals()
                this.statsblock()
                
                }

                if(res.data.data[0].attributes.title === "Switchgears Division"){
                    this.setState({
                        info:"Contact Us - Switchgears divisions page"
                    })
                }
                if(res.data.data[0].attributes.title === "Projects Division"){
                    this.setState({
                        info:"Contact Us - Project Divisions page"
                    })
                }
                console.log(res.data, 'car')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    newarrivals() {
        Axios(baseurl + '/jsonapi/node/products?sort=-created&filter[field_new_arrival][value]=1&include=field_brand,field_image,field_category&page[limit]=6', {
            method: 'GET',
            headers: {
            }
        })
            .then((data) => {
              
                this.setState({
                    newarr: data.data,
                    info:"Contact Us - trading divisions"

                })
                let i, j;
                this.setState({
                    testlogo: true
                })

                let newimgarray = []
                let newcatarray = []
                for (i = 0; i < data.data.data.length; i++) {
                    for (j = 0; j < data.data.included.length; j++) {
                        if (data.data.data[i].relationships.field_image.data.id === data.data.included[j].id) {
                            newimgarray.push(baseurl + data.data.included[j].attributes.uri.url)
                        }
                        if (data.data.data[i].relationships.field_category.data.id === data.data.included[j].id) {
                            newcatarray.push(data.data.included[j].attributes.name)
                        }
                    }
                }
                this.setState({
                    newimgarray: newimgarray,
                    newcatarray: newcatarray
                })

                console.log(data, 'newarr1')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    swprodivn() {
        return (
            <div class="divisions-about-section" id="about">
                <div class="container">
                    <div class="row">
                        <h3 className="col-sm-12 col-12 divnhead">
                            {this.state.api.data[0].attributes.title}
                        </h3>
                        {ReactHtmlParser(this.state.api ? this.state.api.data[0].attributes.field_about.value : '')}
                    </div>
                </div>
            </div>)
    }



    tradingstr() {
        return (
            <div className="divisions-about-section" id="about">
                {this.state.api ?
                    <div className="contain">
                        <div className="row divcontainer1">
                            <div className="divn-1">
                                <div className="col-12 col-sm-12">
                                    <h3 className="col-sm-12 col-12 divnhead">
                                        {this.state.api.data[0].attributes.title}
                                    </h3>
                                </div>
                                {ReactHtmlParser(this.state.api ? this.state.api.data[0].attributes.field_about.value : '')}
                            </div>
                            <div className="divn-2 webtrddiv2">
                                {this.state.newimgarray && this.state.newarr && this.state.newcatarray ?
                                    <div className="tradearr">
                                        <div className="new-arr-header">
                                            <p>
                                                New Arrivals
                                            </p>
                                            <Slide left>
                                                <div className="new-arr-line"></div>
                                            </Slide>
                                        </div>
                                        <NewArrivals proj={true} onSelectLanguage={()=>this.handleLanguage()} cat={this.state.newcatarray} imgs={this.state.newimgarray} data={this.state.newarr} />
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        {this.state.category ?
                            <div className="cat-icons-div">
                                <CatIcons categories={this.state.category.data} CatIcons={this.state.category.included} />
                            </div>
                            : ''
                        }

                            <div className="divn-2 mobtrddiv2">
                                {this.state.newimgarray && this.state.newarr && this.state.newcatarray ?
                                    <div className="tradearr">
                                        <div className="new-arr-header">
                                            <p>
                                                New Arrivals
                                            </p>
                                            <Slide left>
                                                <div className="new-arr-line"></div>
                                            </Slide>
                                        </div>
                                        <NewArrivals proj={true} onSelectLanguage={()=>this.handleLanguage()} cat={this.state.newcatarray} imgs={this.state.newimgarray} data={this.state.newarr} />
                                    </div>
                                    : ''}
                            </div>

                        <div className="quote-trade">
                            <p>Order faster with one click!</p>
                            <div className="hvr-shrink" onClick={()=>this.setState({enqopen:true})}>Get a Quote</div>
                        </div>
                        {this.state.brands ?
                            <div className="fb-brand contain">
                                <div className="brand-icons-div">
                                    {this.state.brands.map((img, index) => {
                                        return (
                                            <div className="cat-icons">
                                                <div className='set' key={index}>
                                                    <img src={baseurl + img.attributes.uri.url}></img>
                                                </div>
                                            </div>
                                        );
                                    }
                                    )
                                    }
                                </div>
                                <div className="fb-embed">
                                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fgulftechgroup%2F&tabs=timeline&width=500&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=536648523572344" width="500" height="500" style={{border:'none',overFlow:'hidden'}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                                    {/* <FacebookProvider appId="536648523572344">
                                        <Page href="https://www.facebook.com/gulftechoman/" tabs="timeline" />
                                    </FacebookProvider> */}
                                </div>
                            </div>
                            : ''
                        }
                    </div>

                    : ''}
            </div>
        )
    }


    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    componentWillMount() {

        switch (this.props.tab) {
            case '2':
                this.setState({
                    indice: 4,
                    gallery_add: '/photogallery_project#gallery',
                    vidtext: "Projects Division",
                    yurl: 3,
                    sec: 6
                })
                break;
            case '3':
                this.setState({
                    indice: 2,
                    gallery_add: '/photogallery_switchgear#gallery',
                    vidtext: "Switchgears Division",
                    yurl: 4,
                    sec: 8
                })
                break;
            case '4': this.setState({
                indice: 8,
                gallery_add: '/photogallery_trading#gallery',
                vidtext: "Trading Division",
                yurl: 5,
                sec: 9
            })
                break;
            default: break;
        }


    }

    handleLanguage () {
        this.setState({
          enqopen:true
        })
    }
    render() {
        return (
            <div className="rev-division">
                <Header  enq={this.state.enqopen} cont={this.props.tab} />
                {this.state.video ?
                    <Navbar url={this.state.video} bannerimg={this.state.bannerimg}/>
                    : ''
                }
                {this.state.newsstrip ?
                    <Updates divisionupdate={`/newsstrip/${this.state.divsn}/${this.state.uuid}`} data={this.state.newsstrip} />
                    : ''}
                {this.state.api ?
                    this.state.api.data[0].attributes.title === "Trading Division" ?
                        this.tradingstr() :
                        this.swprodivn()
                    : ''}
                {this.state.api ?
                    this.state.api.data[0].attributes.title !== "Trading Division" ?
                        <div className="red-gallery">
                            <PGallery path={"field_division"} section={this.state.api.data[0].attributes.title } uuid={this.state.uuid} />
                        </div>
                        : '' : ''}
                {this.state.info?
                <Footerindex info={this.state.info} />
                :''}
                <SecondFooter/>
            </div>
        );
    }
}

export default Division;