import React from 'react';
import Slider from "react-slick";
import axios from "axios";
import { baseurl } from './API';
import Axios from 'axios';

export default class Slides extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            base: baseurl,
            st: [],
            data:[],
            width:window.innerWidth
        };
    }   
    
    
    componentWillReceiveProps(nextProps) {
        console.log(nextProps,"pgal")
        this.datacall(nextProps.uuid)
    }

    datacall(uuid){
        let photos = []
        Axios(`${baseurl}/jsonapi/node/brands?sort=-created&include=field_logo&filter[idFilter][condition][path]=${this.props.path}.uuid&filter[idFilter][condition][value]=`+uuid, {
            method: 'GET',
            headers: {
            }
        })
        .then(({ data }) => {
            console.log(data,"slid")
                this.setState({
                    data:data.data
                })
            data.included.map((img, index) => {
                photos.push(this.state.base + img.attributes.url);
            })
            console.log(photos)
            this.setState({ st: photos })
        })
        .catch((err) => { })
    }


    componentDidMount() {
        this.datacall(this.props.uuid)

    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 1500,
            autoplaySpeed: 2000,
            slidesToShow: this.state.width > 767 ? this.state.st.length > 5 ? 6 : this.state.st.length: this.state.st.length > 2 ? 2 : this.state.st.length,
            slidesToScroll: 1,
            autoplay:true
        };
        return (
            <div style={{display:this.state.data.length===0?"none":'',width:this.state.width > 767 ? this.state.st.length < 6?'60%':'':'',margin:this.state.width > 767 ? this.state.st.length < 6?'50px auto':'':''}} className='slides'>
                <Slider {...settings}>
                    {this.state.st.map((img, index) => {
                        return (
                            <div className='set' key={index}>
                                <img src={img}></img>
                            </div>
                        );
                    }
                    )
                    }
                </Slider>
            </div>
        );
    }
}
