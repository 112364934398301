import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';
import WordLimit from 'react-word-limit';
import Slider from "react-slick";

class NewArrivals extends Component {
    constructor(props) {
        super(props);
        this.state={
            width:window.innerWidth
        }
    }

    handleLangChange() {
        this.props.onSelectLanguage('lang');
    }

    render() {
        const settings2 = {
            dots: false,
            infinite: true,
            speed: 1500,
            slidesToShow:this.state.width<1050?this.state.width<900?4:5:6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000
        };

        return (
            <div className="new-arr">
                {this.state.width>767&&this.props.proj===false?
                <Slider {...settings2}>
                {this.props.data.data.map((stat, index) => {
                        return (
                            <div key={index} className="new-box">
                                <div style={{ backgroundImage: `url(${this.props.imgs[index]})` }} id="imagecontainer"></div>
                                <div className="new-text-sec">
                                    <div className="new-tit">
                                        <WordLimit limit={25}>{stat.attributes.title}</WordLimit>
                                    </div>
                                    {this.props.cat[index] ?
                                        <div className="new-type">
                                            {this.props.cat[index]}
                                        </div>
                                        : ""}
                                    <div onClick={() => this.handleLangChange()} className="new-enq hvr-shrink">
                                        Enquire
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )
                    }
                </Slider>
                :<div className="new-arrivals">
                {this.props.data.data.map((stat, index) => {
                    return (
                        <Slide key={index} right>
                            <div key={index} className="new-box">
                                <div style={{ backgroundImage: `url(${this.props.imgs[index]})` }} id="imagecontainer"></div>
                                <div className="new-text-sec">
                                    <div className="new-tit">
                                        <WordLimit limit={25}>{stat.attributes.title}</WordLimit>
                                    </div>
                                    {this.props.cat[index] ?
                                        <div className="new-type">
                                            {this.props.cat[index]}
                                        </div>
                                        : ""}
                                    <div onClick={() => this.handleLangChange()} className="new-enq hvr-shrink">
                                        Enquire
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    )
                })}
            </div> }
                
                
            </div>)
    }
}
export default NewArrivals