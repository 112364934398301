import React, { Component } from 'react';
import Axios from 'axios';
import {baseurl} from './API'
import ReactPlayer from 'react-player';

class Video extends Component {

  constructor(props) {
    super(props);
    this.state = {
       api:'',
       vidsrc:this.props.vidsrc+1,
       playtrue:props.play,
       vidurl:props.url

    }
}
  componentWillReceiveProps(nextProps){
    console.log(nextProps)
    this.setState({
      playtrue:nextProps.play,
      vidurl:nextProps.url
    })
  }

//   componentWillMount(){
    
    
//     Axios(baseurl+'/jsonapi/block_content/basic', {
//         method: 'GET',
//         headers: {
//         }
//     })
//         .then((res) => {
//           this.setState({
//             api: res.data
//         })
//         console.log(res.data.data,'video')
           
//         })
//         .catch((err) => {
//             console.log(err)
//         })
// }
 
  render() {
    return (
         <div className="video_react" style={{overflow: 'hidden',width: '100%',top: '-6px',right: '0px',paddingBottom: '57.25%'}}>
            <ReactPlayer width={'101%'} url={this.state.vidurl} playing={this.state.playtrue}  loop={true} />
            {/* <iframe title="gulftech" frameborder="0" src={this.state.api?this.state.api.data[this.props.vidsrc].attributes.field_link.uri+"?autoplay=1&amp;loop=1&amp;mute=1&amp;playlist=m49cvVnJcYQ&amp;controls=0&amp;showinfo=0":''} allowfullscreen="" autoplay="1" >
              </iframe> */}
         </div>
    );
  }
}

export default Video;