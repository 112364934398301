import React from 'react'
import { baseurl } from './API';
import { Link } from "react-router-dom";

const CatIcons = (props) => (
    <div className="cat-icons-sec">
        {props.CatIcons.map((img, index) => {
            return (
                <Link className="cat-icons" key={index} to={`/category/${props.categories[index].attributes.name}/${props.categories[index].attributes.uuid}`}>
                <div>
                    <div className='set' key={index}>
                        <img src={baseurl + img.attributes.uri.url}></img>
                    </div>
                    <div className='cat-icons-name' key={index}>
                        {props.categories[index].attributes.name}
                    </div>
                </div>
                </Link>

            );
        }
        )
        }
    </div>

)

export default CatIcons