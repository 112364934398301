import React from 'react';
import Slider from "react-slick";
import { baseurl } from './API';

export default class Major extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      p1: props.brands.slice(0, props.brands.length / 2),
      p2: props.brands.slice(props.brands.length / 2)
    };
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1500,
      slidesToShow: this.state.width > 767 ? this.state.p1.length > 5 ? 6 : this.state.p1.length : this.state.p1.length > 2 ? 2 : this.state.p1.length,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500
    };
    const settings2 = {
      dots:false,
      infinite: true,
      speed: 1500,
      slidesToShow: this.state.width > 767 ? this.state.p2.length > 5 ? 6 : this.state.p2.length : this.state.p2.length > 2 ? 2 : this.state.p2.length,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500
    };
    return (
      <div className='majordeals slides'>
        <Slider {...settings}>
          {this.state.p1.map((img, index) => {
            return (
              <div className='set' key={index}>
                <img src={baseurl + img.attributes.uri.url}></img>
              </div>
            )
          }
          )
          }
        </Slider>
        <Slider {...settings2}>
          {this.state.p2.map((img, index) => {
            return (
              <div className='set' key={index}>
                <img src={baseurl + img.attributes.uri.url}></img>
              </div>
            )
          }
          )
          }
        </Slider>
      </div>
    );
  }
}


