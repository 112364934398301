import React, { Component } from 'react';
import Axios from 'axios';
import { baseurl } from "./API";
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom'

class HomeTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: '',
            testindex: 0,
        }
    }
    componentDidMount() {
        Axios(baseurl + '/jsonapi/node/divisions?sort=-title&include=field_images', {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    tab: res.data
                })
                console.log(res.data, 'tab')

            })
            .catch((err) => {
                console.log(err)
            })
    }


    render() {
        return (
            <div className="retabs">
                {this.state.tab ?
                    this.state.tab.data.map((tab, index) => {
                        return (
                        <div style={{flexDirection:index%2!==0?"row-reverse":"",padding:index%2?"20px 0px 20px 0px":''}} className="retab-div">
                            <div className="re-tab-img">
                                <img src={baseurl + this.state.tab.included[index].attributes.uri.url} alt="tbaimh" />
                            </div>
                            <div style={{paddingLeft:index%2?"0":''}} className="re-tab-main">
                                <div className="re-tab-head">
                                    {tab.attributes.title}
                                </div>
                                <div className="re-tab-sub">
                                    {ReactHtmlParser(tab.attributes.body.value)}
                                </div>
                                <div className="read-more hvr-shrink">
                                    <Link to={`/division${tab.attributes.path.alias}/${tab.attributes.uuid}`}>
                                    Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        
                        )
                    })
                    : ''}
            </div>
        )
    }
}

export default HomeTabs
