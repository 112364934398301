import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css"

class Updates extends Component {
    constructor(props) {
        super(props);
        console.log(props, "deveeni")
        this.state = {
            data: props.data,
            divisionupdate: props.divisionupdate,
            newsupdate: props.newsupdate
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data,
            divisionupdate: nextProps.divisionupdate,
            newsupdate: nextProps.newsupdate
        })
    }
    render() {
        console.log(this.props)
        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            vertical: this.state.data.data.length!==1?true:false,
            verticalSwiping: true,
            autoplay: true,
            beforeChange: function (currentSlide, nextSlide) {
                console.log("before change", currentSlide, nextSlide);
            },
            afterChange: function (currentSlide) {
                console.log("after change", currentSlide);
            }
        };
        return (
            <div class="vertupdate">
                <div class="container">
                    <Slider {...settings}>
                        {this.state.data.data.map((news, index) => {
                            return (
                                <h5><Link to={`/updates/${news.attributes.uuid}`}><span>{news.attributes.title}</span></Link></h5>
                            )
                        })}
                    </Slider>
                    {/* <div class="row">
                        <div class="col-sm-12">
                            <div id="ticker">
                                <ul id="tick" className="headerlatest">
                                    <li style={{ backgroundColor: 'rgb(0, 0, 0)', opacity: 1 }}><h5>
                                        <Slider {...settings}>
                                            {this.state.data.data.map((news, index) => {
                                                return(
                                                    <h5><Link to={`/updates/${news.attributes.uuid}`}><span>{news.attributes.title}</span></Link></h5>
                                                )
                                            })}
                                        </Slider>
                                    </h5></li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default Updates;