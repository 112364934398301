import React, { Component } from 'react';
import '../App.css';
import Footerindex from './Footerindex';
import Axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import pdf from '../img/pdf.png';
import { animateScroll as scroll } from 'react-scroll'
import { baseurl } from "./API";
import Header from './header';
import SecondFooter from './secondFooter';


class Newstrip extends Component {
  constructor(props) {
    super(props);
    console.log(props, "prosss")
    this.state = {
      sec: 1,
      uuid: props.match.params.uuid,
      divsn: props.match.params.divsn,
      yurl: "",
      api: '',
      tab: this.props.tab,
      indice: 2,
      gallery_add: "",
      vidtext: "",
      cont: ["Trade offers", "Switchgear offers", "Project Offers"],
      pdfarray: []
    }
  }

  componentDidMount() {
    this.newsstrip(this.state.uuid)
  }

  componentWillReceiveProps(nextProps) {
    this.apicall(nextProps.match.params.uuid)
  }

  newsstrip(uuid) {
    Axios(baseurl + '/jsonapi/node/newsstrip?include=field_pdf_file&filter[idFilter][condition][path]=uuid&filter[idFilter][condition][value]=' + uuid, {
      method: 'GET',
      headers: {
      }
    })
      .then((data) => {
        console.log(data,'dev')
        this.setState({
          newsstrip: data.data
        })
        let i, j, logoarray = []
        for (i = 0; i < data.data.data.length; i++) {

          if (data.data.data[i].relationships.field_pdf_file.data !== null) {
            for (j = 0; j < data.data.included.length; j++) {
              if (data.data.data[i].relationships.field_pdf_file.data.id === data.data.included[j].id) {
                logoarray.push(baseurl + data.data.included[j].attributes.uri.url)
                this.setState({
                  padfname: data.data.included[j].attributes.filename
                })
              }
            }
          }
          else {
            logoarray=[]
          }

        }
        this.setState({
          pdfarray: logoarray
        })
        console.log(logoarray,this.state.padfname, "zzz")
        console.log(data.data, 'newws')
        this.statsblock()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  scrollTo() {
    scroll.scrollTo(0);
  }

  componentWillMount() {



  }
  render() {
    return (
      <div className="cat_pag updatepage">
        <Header />
        {this.state.newsstrip ?
          <div className="rednews contain">
            <div className="rednews-head">
              {this.state.newsstrip.data[0].attributes.title}
            </div>
            <div id="rednews" className="rednew-div">
              {this.state.newsstrip ?
                this.state.newsstrip.data[0].attributes.body ?
                  <div className="cat-desc">
                    {ReactHtmlParser(this.state.newsstrip ? this.state.newsstrip.data[0].attributes.body.value : '')}
                  </div>
                  : '' : ''}
            </div>
            {this.state.pdfarray.length!==0 ?
            <div id="pdflink" className="rednews-div">
                <div>
                  <img src={pdf} alt='pdf'/>
                  <a target="_blank" href={this.state.pdfarray[0]}>{this.state.padfname}</a>
                </div>
            </div>
                            : ''}
          </div>
          : ''}
            <Footerindex info={"Contact Us - Home Page"} />
        <SecondFooter />
      </div>
    );
  }
}

export default Newstrip;