import React, { Component } from 'react';
import Axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { baseurl } from "./API";
import MapWrapper from './mapwrapper';

class Footerindex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: this.props.info,
            api: ''
        }
    }
    apicall(info){
        Axios(baseurl + '/jsonapi/block_content/basic?filter[info]='+info, {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    api: res.data
                })
                console.log(res,'footer')
            })
            .catch((err) => {
                console.log(err)
            })
    }
    componentDidMount() {
         this.apicall(this.state.info)
    }

    componentWillReceiveProps(nextProps){
        this.apicall(nextProps.info)
    }
    render() {
        return (

            <div class="footer-div">
                <div class="footer-sec">
                    <div className="foot-map">
                        <MapWrapper/>
                    </div>
                    <div className="foot-contact">
                    {ReactHtmlParser(this.state.api ? this.state.api.data[0].attributes.body.value : '')}
                    </div>
                </div>
            </div>
        );
    }
}

export default Footerindex;

