import React, { Component } from 'react';
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import Homepage from './components/Homepage';
import Division from './components/Division';
import PGallery from './components/PGallery';
import Error404 from './components/404';
import Error403 from './components/403';
import Newsstrip from './components/Newsstrip';
import GoogleMapComponentWithMarker from './components/map';
import MapWrapper from './components/mapwrapper';
import Category from './components/category';




class App extends Component {
    
  render() {
    return (
      <Router>
        <div>
        <Switch>
          <Route path="/" component={Homepage} exact/>
          <Route path="/home" component={Homepage} exact/>
          <Route path="/map" component={MapWrapper} exact/>
          <Route path="/403" component={Error403} exact/>
          <Route path="/updates/:uuid" component={Newsstrip} />
          <Route path="/newsstrip/:divsn/:uuid" component={Newsstrip} />
          <Route path="/pdnewsstrip" render={()=><Newsstrip  vidprop="2" vidind="3" />} exact/>
          <Route path="/sdnewsstrip" render={()=><Newsstrip  vidprop="3" vidind="4"/>} exact/>
          <Route path="/tdnewsstrip" render={()=><Newsstrip  vidprop="4"  vidind="5"/>} exact/>
          <Route path="/division/:divsn/:uuid" component={Division} />
          <Route path="/category/:name/:uuid" component={Category} />
          <Route path="/photogallery_project" render={()=><PGallery tab="2" vidind="3"/>} exact/>
          <Route path="/photogallery_switchgear" render={()=><PGallery tab="3" vidind="4"/>} exact/>
          <Route path="/photogallery_trading" render={()=><PGallery tab="4"  vidind="5"/>} exact/>
          <Route path="/project-division" render={()=><Division tab="2" vidind="3"/> } exact/>
          <Route path="/switchgears-divisions" render={()=><Division tab="3" vidind="4"/>} exact/>
          <Route path="/trading-division" render={()=><Division tab="4" vidind="5"/>} exact/>
          <Route path="/*" component={Error404} exact/>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
