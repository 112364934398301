import React, { Component } from 'react';
import './Pgallery.css';
import Axios from 'axios';
import { baseurl } from "./API";
import Fade from 'react-reveal/Fade';
var maxl;

class PGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sec: 1,
            popup: false,
            index: 0,
            api: '',
            tab: this.props.tab,
            vid_text: "",
            yurl: "",
            cont: ["Trade offers", "Switchgear offers", "Project Offers"],
            uuid:props.uuid
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps,"pgal")
        if(nextProps.section !== "Trading Division" )
        {
            if(this.state.uuid!==nextProps.uuid){
                this.datacall(nextProps.uuid)
            this.setState({
                uuid:nextProps.uuid
            })
            }
            
        }
        else{
            alert('gallery off')

            this.setState({
                api:''
            })
        }
    }

    datacall(uuid){
        this.setState({
            api:''
        })
        Axios(baseurl + `/jsonapi/node/image_gallery?sort=-created&filter[idFilter][condition][path]=${this.props.path}.uuid&filter[idFilter][condition][value]=${uuid}&include=field_gallery`, {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    api: res.data
                })
                console.log(this.state.api, 'datagal')
                maxl = this.state.api.included.length
                console.log(maxl, 'data')
            })
            .catch((err) => {
                console.log(err)
            })
    }


    componentDidMount() {
        if(this.props.section !== "Trading Division" )
        this.datacall(this.props.uuid)
        else{
            this.setState({
                api:''
            })
        }
        switch (this.props.tab) {
            case '2':
                this.setState({
                    vidtext: "Projects Division",
                    yurl: 3,
                    sec: 6
                })
                break;
            case '3':
                this.setState({

                    vidtext: "Switchgears Division",

                    yurl: 4,
                    sec: 8
                })
                break;
            case '4': this.setState({

                vidtext: "Trading Division",
                yurl: 5,
                sec: 9
            })
                break;
            default: break;
        }
    }



    popupchange(status) {
        if(status){
            document.body.style.overflow = 'auto';
        }
        else
            document.body.style.overflow = 'hidden';
        this.setState({
            popup: !this.state.popup
        })
        if (window.outerWidth > 768) {
            document.body.classList.add(this.state.popup ? "add" : "home");
            document.body.classList.remove(this.state.popup ? "home" : "add");
        }
    }

    nextb() {
        this.setState({
            index: this.state.index + 1
        })

    }

    prevb() {
        this.setState({
            index: this.state.index - 1
        })

    }

    changeind(id) {
        this.setState({
            index: id
        })
    }

    render() {
        return (
            <div>
                {this.state.api ? 
                    this.state.api.included ?
                    <div className='imagepopup' style={{ display: this.state.popup ? '' : 'none' }}>
                    <span class="closeb cursor" onClick={() => this.popupchange(this.state.popup)}>×</span>
                    <div class="prev-next-buttonb">
                        <a style={{ visibility: this.state.index < 1 ? 'hidden' : '' }} class="prevb" onClick={() => this.prevb()}>❮</a>
                        <div class="red-gal-pop-img" containerId="gallery-popup-col">
                            <img src={this.state.api ? baseurl + this.state.api.included[this.state.index].attributes.url : ''} alt="photogallery" class="divisions-photo img-responsive hover-shadow" />
                        </div>
                        <a style={{ visibility: this.state.index > maxl - 2 ? 'hidden' : '' }} class="nextb" onClick={() => this.nextb()}>❯</a>
                    </div>
                </div>
                    :'':''
                }
                
                <div className="red-gal">
                    {this.state.api ? 
                    this.state.api.included ? 
                    this.state.api.included.map((data, index) => {
                        return (
                            <Fade up>
                                <div onClick={() => this.changeind(index)} className="hvr-grow red-gal-img" id="gallery-popup-col">
                                    <img src={this.state.api ? baseurl + data.attributes.url : ''} alt="gulftech" class="divisions-photo img-responsive hover-shadow cursor" onClick={() => this.popupchange(this.state.popup)} />
                                </div>
                            </Fade>
                        )
                    }):'' : ''}
                </div>
            </div>
        );
    }
}

export default PGallery;