// Import React and React DOM
import * as React from 'react'
import { Link } from "react-router-dom";
import gtmap from '../img/gtmap.jpg'
// Some default styles
const styles = {
  width: '100%',
  height: '536px'
}

// Wrapper with Google Map component
class MapWrapper extends React.PureComponent {
  render() {
    return (
      <div className="mapframe" style={styles}>
        <a target="_blank" href="https://www.google.com/maps/d/embed?mid=1AJqQLuMQ7Cxn4ISUgFgchZXHkTf7KOYj&hl=en" className="maplink"/>
        <img src={gtmap} alt="map"/>
      </div>
    )
  }
}

// Render everything in HTML
export default MapWrapper