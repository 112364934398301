import React, { Component } from 'react';
import error from '../img/403.jpg'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Error403 extends Component {
 
  
  render() {
    
    return (
         <div class="container">

                <div class="row">
                    <div class="col-sm-12 error-section">
                         <img src={error} alt="error" class="error-img img-responsive" />
                        <h3>ACCESS DENIED</h3>
                        <p class="error-para">You dont have permission to access this page. </p>
                        <h3 class="error-head cursor"> <Link to="/"> GO TO HOME</Link> </h3>
                    </div>
                </div>   
    </div>
    );
  }
}

export default Error403;