import React, { Component } from 'react';
import '../App.css';
// import Header1 from './header1';
import Axios from 'axios';
import { baseurl } from "./API";
import Header from './header';
import CarousalApp from './gallery';
import Stats from './stats';
import HomeTabs from './hometabs';
import Major from './major';
import NewArrivals from './newArrivals';
import cachios from 'cachios'
import quote from '../img/quote-watermark.png'
import activeleft from '../img/Active left.png'
import deactiveleft from '../img/De active left.png'
import activeright from '../img/active right.png'
import deactiveright from '../img/De active right.png'
import dummy from '../img/dummyimg.jpg'
import white from '../img/white.png'
import ReactHtmlParser from 'react-html-parser';
import Slide from 'react-reveal/Slide';
import Footerindex from './Footerindex';
import SecondFooter from './secondFooter';
import Updates2 from './updatepage';
// import Navbar from './Navbar';



class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cont: ["Trade offers", "Switchgear offers", "Project Offers"],
      carousal: null,
      testindex: 0,
      sec: 1,
      enqopen:false
    }
  }

  testindex(id) {

    if (id === 1) {
      if (this.state.testindex !== 0) {
        this.setState({
          testindex: this.state.testindex - 1
        })

      }
      else {
        this.setState({
          testindex: 0
        })
      }

    }

    else {
      if (this.state.testlen - 1 !== this.state.testindex) {
        this.setState({
          testindex: this.state.testindex + 1
        })
      }
      else {
        this.setState({
          testindex: this.state.testlen - 1
        })
      }
    }
  }

  newarrivals() {
    Axios(baseurl + '/jsonapi/node/products?sort=-changed&filter[field_new_arrival][value]=1&include=field_brand,field_image,field_category', {
      method: 'GET',
      headers: {
      }
    })
      .then((data) => {
        this.setState({
          newarr: data.data
        })
        let i, j;
        this.setState({
          testlogo: true
        })

        let newimgarray = []
        let newcatarray = []
        for (i = 0; i < data.data.data.length; i++) {


          for (j = 0; j < data.data.included.length; j++) {
            if(data.data.data[i].relationships.field_image.data)
            if (data.data.data[i].relationships.field_image.data.id === data.data.included[j].id) {
              newimgarray.push(baseurl + data.data.included[j].attributes.uri.url)
            }
            if(data.data.data[i].relationships.field_category.data)
            if (data.data.data[i].relationships.field_category.data.id === data.data.included[j].id) {
              newcatarray.push(data.data.included[j].attributes.name)
            }
          }

        }

        this.setState({
          newimgarray: newimgarray,
          newcatarray: newcatarray
        })

        console.log(data, 'newarr1',newimgarray,newcatarray)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  majors() {
    Axios(baseurl + '/jsonapi/node/brands?include=field_logo&filter[field_featured][value]=1', {
      method: 'GET',
      headers: {
      }
    })
      .then((res) => {
        this.setState({
          majors: res.data.included
        })
        console.log(res.data.included, 'major')
        
      })
      .catch((err) => {
        console.log(err)
      })
  }

  statsblock() {
    Axios(baseurl + '/jsonapi/block_content/stats_block?include=field_icon&page[limit]=6', {
      method: 'GET',
      headers: {
      }
    })
      .then((res) => {
        this.setState({
          statsblock: res.data
        })
        this.majors()
        this.newarrivals()
        this.testimonial()

        console.log(res.data, 'stats')
      })
      .catch((err) => {
        console.log(err)
      })
  }


  testimonial() {


    cachios.get(baseurl + '/jsonapi/node/testimonials?include=field_display_picture', {
      ttl: 300 /* seconds */,
    })
      .then((data) => {
        let i, j;

        this.setState({
          testimonial: data.data.data.reverse(),
          testlen: data.data.data.length,
        })
        this.setState({
          testimg: true
        })
        let imagearray = []
        for (i = 0; i < data.data.data.length; i++) {
          if (data.data.data[i].relationships.field_display_picture.data !== null) {
            for (j = 0; j < data.data.included.length; j++) {
              if (data.data.data[i].relationships.field_display_picture.data.id === data.data.included[j].id) {
                imagearray.push(baseurl + data.data.included[j].attributes.uri.url)
              }
            }
          }
          else {
            imagearray.push(dummy)
          }

        }
        this.setState({
          imagearray: imagearray
        })

        if (imagearray.length === data.data.data.length) {
          this.setState({
            testimg: true
          })
        }




      })

      .catch((err) => {
        console.log('not found')
      })

    //testimonLogo

    cachios.get(baseurl + '/jsonapi/node/testimonials?include=field_logo', {
      ttl: 300 /* seconds */,
    })
      .then((data) => {
        let i, j;
        this.setState({
          testlogo: true
        })
        let logoarray = []



        for (i = 0; i < data.data.data.length; i++) {

          if (data.data.data[i].relationships.field_logo.data !== null) {
            for (j = 0; j < data.data.included.length; j++) {
              if (data.data.data[i].relationships.field_logo.data.id === data.data.included[j].id) {
                logoarray.push(baseurl + data.data.included[j].attributes.uri.url)
              }
            }
          }
          else {
            logoarray.push(white)
          }

        }
        this.setState({
          logoarray: logoarray.reverse()
        })
        if (logoarray.length === data.data.data.length) {
          this.setState({
            testlogo: true
          })
        }




      })
      .catch((err) => {
        console.log('not found')
      })


  }

  componentDidMount() {
    Axios(baseurl + '/jsonapi/block_content/banner_slider?include=field_banner_image', {
      method: 'GET',
      headers: {
      }
    })
      .then((res) => {
        this.setState({
          carousal: res.data
        })
        console.log(res.data, 'car')
        this.statsblock()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  handleLanguage () {
    this.setState({
      enqopen:true
    })
}

  render() {
    return (
      <div className="App">
        <div className="rehomehead">
          <Header enq={this.state.enqopen} cont={0} />
          {this.state.carousal ?
            <CarousalApp cdata={this.state.carousal} />
            : ''}
          {this.state.statsblock ?
            <div className="statsHome contain">
              <Stats stat={this.state.statsblock} />
            </div>
            : ''}
          <HomeTabs />
          {
            this.state.majors ?
              <div className="red-major contain">
                <div className="rednews-head">
                  Major Dealerships Include
            </div>
                <Major brands={this.state.majors} />
              </div>
              : ''
          }


          {/* {this.state.statsblock ?
            <div className="statsHome">
              <Major stat={this.state.statsblock} />
            </div>
            : ''} */}
          {this.state.newimgarray && this.state.newarr && this.state.newcatarray ? this.state.newimgarray.length === this.state.newarr.data.length && this.state.newcatarray.length === this.state.newarr.data.length ?
            <div className="new-arr-div contain">
              <div className="new-arr-header">
                <p>
                  New Arrivals
                </p>
                <Slide left>
                  <div className="new-arr-line"></div>
                </Slide>
              </div>

              <NewArrivals proj={false} onSelectLanguage={()=>this.handleLanguage()} cat={this.state.newcatarray} imgs={this.state.newimgarray} data={this.state.newarr} />


              {this.state.testimonial && this.state.testimg && this.state.testlogo ?

                <div className='testimonials homtestimonial'>
                  <div className="new-test-header">
                    <div className='card-title'>Testimonials</div>
                    <div className='quote'>
                      <img src={quote} height='70px' alt='hometitle' />
                    </div>
                  </div>
                  {this.state.testimonial ?
                    <div className="testimonial_div">
                      <div className='testimonial_header'>
                        <div className='testimonial_header_div1'>
                          <div>
                            <img className='img-circle' src={this.state.imagearray ? this.state.imagearray[this.state.testindex] : dummy} height='120px' width='120px' alt='hometitle' />
                          </div>
                          <div className='profile'>
                            <div className='card-title'>{this.state.testimonial[this.state.testindex].attributes.title}</div>
                            {this.state.testimonial[this.state.testindex].attributes.field_designation ?
                              <div className="card-text"> {this.state.testimonial[this.state.testindex].attributes.field_designation}</div> : ''}
                          </div>
                        </div>

                      </div>
                      {this.state.testimonial[this.state.testindex].attributes.body ? <div className="testimonial_desc">
                        {ReactHtmlParser(this.state.testimonial[this.state.testindex].attributes.body.value)}
                      </div> : ''}
                      <div className='testimonial_footer'>
                        <div>
                          {this.state.logoarray ? <img className='img-circle' src={this.state.logoarray ? this.state.logoarray[this.state.testindex] : white} height='30px' alt='hometitle' /> : ''}
                        </div>
                        <div className='testimonial_arrow'>
                          <div className="cursor"><img onClick={() => this.testindex(1)} src={this.state.testindex === 0 ? deactiveleft : activeleft} alt='hometitle' /></div>
                          <div className="cursor"><img onClick={() => this.testindex(2)} src={this.state.testindex === this.state.testlen - 1 ? deactiveright : activeright} alt='hometitle' /></div>
                        </div>
                      </div>
                    </div>
                    : <div></div>
                  }
                </div>
                : ''}
            </div> : '' : ''}
            <Footerindex info={"Contact Us - Home Page"} />
            <SecondFooter/>
        </div>
      </div>
    );
  }
}

export default Homepage;