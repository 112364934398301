import React, { Component } from 'react';
// import './gulftech.css';
import './revamped.css';
import logo from '../img/Logo.png';
import ham from '../img/redmenu.png';
import search from '../img/searchsmall.jpg';
import redsearch from '../img/redsearch.png';
import Slide from 'react-reveal/Slide';
import mobclose from '../img/Close mobile.png';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { baseurl } from "./API";
import { animateScroll as scroll } from 'react-scroll'
var scrollheight = 700;
var link = ["/pdnewsstrip", "/sdnewsstrip", "/tdnewsstrip"];

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollheight: "700",
            projarray: [],
            switcharray: [],
            tradearray: [],
            latestnews: [],
            mobmenu: false,
            searchinp: '',
            showinp: false,
            inpfocus: false,
            searchres: null,
            quote: false,
            btntext: 'Send',
            prodnum: [1],
            storearea: ['Dubai','Salalah','Muscat'],
            sections: ['Trading', 'Switchgear', 'Projects'],
            area: 'Store Area',
            formvalidtext:'Please Enter Valid Details',searchaaray:[],
            quote:false,
            formsuccess:false,
            headersicky:false
        }
        this.handleScroll = this.handleScroll.bind(this);

    }

    componentWillUnmount(){
        this.setState({
          absolute:false,
          headersicky:false
        })
      }
    
      componentDidMount() {
        if(this.state.quote){
            document.body.style.overflow = 'hidden';
          }   
        this.catogery()
          window.addEventListener('scroll', this.handleScroll);
      }
    
      handleScroll(event) {
        var w = window.innerWidth;
        var h = document.getElementById("root").offsetHeight;
        // console.log(window.pageYOffset, h)
    
        if (h < 740) {
          if (window.pageYOffset > 40) {
            // alert('fixed')
            this.setState({
              headersicky: true,
              absolute: true
            })
          }
          if (window.pageYOffset === 0)
            this.setState({
              absolute: false,
              headersicky: false
            })
        }
        else {
          this.setState({
            absolute: false
          })
          if (window.pageYOffset > 40) {
            // alert('fixed')
            this.setState({
              absolute: true,
              headersicky: true,
    
            })
          }
          else {
            this.setState({
              headersicky: false
            })
          }
        }
      }
 
  
      componentWillUnmount() {
          document.body.style.overflow = 'unset';
          window.removeEventListener('scroll', this.handleScroll);
          this.setState({
            headersicky:false,
          })
      }

    componentWillReceiveProps(nextProps) {
        this.setState({
            mobmenu: false,
            searchinp: '',
            showinp: false,
            inpfocus: false,
            searchres: null,
            quote: false
        })
        console.log(nextProps,'nextProps')
        if(nextProps.enq===true){
            this.setState({ quote: !this.state.quote })
        }
        
    }
    

    timeout(type,time){
        setTimeout(
            function () {
                this.setState({ [type]: false,formvalidtext:'Please Enter Valid Details' ,formsuccess:false});
            }
                .bind(this),
            time
        );
    }


    formsubmit() {
        this.setState({
            btntext: 'Sending...'
        })
        if(this.state.qname&&this.state.qemail&&this.state.qmsg&&this.state.qnum&&this.state.sectn&&this.state.area){
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(this.state.qemail) == true) 
        {
            const formbody ={
                "contact_form": [{ "target_id": "enquire" }],
                "subject": [{ "value": "Enquire Form" }],
                "message": [{ "value": this.state.qmsg}],
                "field_mobile": [{ "value": this.state.qnum}],
                "field_email": [{ "value": this.state.qemail}],
                "field_name": [{ "value": this.state.qname}],
                "field_section": [{ "value": this.state.sectn}],
                "field_store_area": [{ "value": this.state.area}]
            }
            console.log(formbody)
    
            Axios(baseurl + '/contact_message?_format=json', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: formbody
            })
                .then((res) => {
                    this.setState({
                        qdetails: '',
                        qemail: '',
                        qmsg: '',
                        qnum: '',
                        quote: false,
                        formvalid:true,
                        formsuccess:true,
                        formvalidtext:"Enquiry Sent!",
                        btntext: 'Send'
                    })    
                    this.timeout('formvalid',3000)       
                })
                .catch((err) => {
                    console.log(err)
                    this.setState({
                        formvalid: true,
                        btntext: 'Send'
                    })
                    this.timeout('formvalid',3000) 
    
                })
            }
            else{
                this.setState({
                    formvalid: true,
                    formvalidtext: 'Please enter valid email address'
                })
                this.timeout('formvalid',3000)       
             }
        }
        else{
            this.setState({
                formvalid: true,
                btntext: 'Send'
            })
            this.timeout('formvalid',3000)       
         }
       
    }

    searchapi(key) {
        Axios(baseurl + `/api/search?search=${key}`, {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    searchres: res.data
                })
                let i, j, searchaaray = [], catarray = [], catuuid = []
                if(res.data.length>0){

                if(this.state.searchinp)
                if(this.state.searchinp.length>1){
                    for (i = 0; i < res.data.length; i++) {
                        let testarray = res.data[i].field_tags.split(',')
                        for (j = 0; j < testarray.length; j++) {
                            if (testarray[j].toLowerCase().includes(this.state.searchinp.replace(/&/g,'&amp;').toLowerCase())) {
                                searchaaray.push(testarray[j])
                                catarray.push(res.data[i].name)
                                catuuid.push(res.data[i].uuid_1)
                            }
                        }
                    }
                    console.log(searchaaray, 'xx1', catarray)
                    this.setState({
                        catarray: catarray,
                        searchaaray: searchaaray,
                        catuuid: catuuid
                    })
                }
               

            }
            else{
                this.setState({
                    searchaaray: []
                })
            }
                
            })
            .catch((err) => {
                console.log(err)
            })
    }

    showinp() {
        this.setState({
            showinp: !this.state.showinp
        })
    }

    updateDimensions() {
        if (window.outerWidth < 1024 && window.outerWidth >= 768) {
            scrollheight = 450


        }

        else if (window.outerWidth < 768) {
            scrollheight = 285


        }
        else if (window.outerWidth > 1024) {
            scrollheight = 700


        }


    }
    catogery() {
        Axios(baseurl + '/jsonapi/taxonomy_term/category?page[limit]=10&sort=field_weight', {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    cats: res.data.data
                })

                console.log(res.data, 'catss')
            })
            .catch((err) => {
                console.log(err)
            })
    }


    popupchange(status) {
        if(status){
            document.body.style.overflow = 'auto';
        }
        else
            document.body.style.overflow = 'hidden';
        
    }
 
    closeinput() {
        setTimeout(function () {
            this.setState({
                showinp: false,
                searchres: false
            })
        }, 2000);
    }
    inputchange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    scrollTo() {
        scroll.scrollTo(scrollheight);
    }
    render() {
        return (
            <div className="reheader">
                <div style={{position:'fixed',top:'0',width:'100%', display: this.state.formvalid ? '' : 'none', backgroundColor: this.state.formsuccess ? '#28a745' : '' }} className='formvalidation'>
                    {this.state.formvalidtext}
                </div>
                {this.state.headersicky?
                    <div className={this.state.headersicky?'fadein':'fadeout'}>
                            <div className="retopsec headersicky contain">
                    <div className="relogo">
                        <Link to="/">
                            <img src={logo} alt="gulftechlogo" />
                        </Link>
                    </div>
                    <div onClick={() => this.setState({ mobmenu: !this.state.mobmenu })} className="mob-ham">
                        <img src={ham} alt="ham" />
                    </div>
                    <div className="reright">
                        <div className="headdiv">
                            <Link to='/division/trading-division/27fca701-5bee-46d1-b49b-7d57fde95975'>
                                Trading Division
                            </Link>
                            <Link to='/division/project-division/4de860d3-23ca-405e-bc2d-03da1d3ac325'>
                                Projects Division
                            </Link>
                            <Link to='/division/switchgears-divisions/9c6414e6-ecd4-414c-b05a-fd5a44adfa1f'>
                                Switchgears Division
                            </Link>
                        </div>
                        <div className="search-section">
                            {/* {this.state.showinp ? */}
                                <div className="search-bar" >
                                    <input className={this.state.showinp?"inputlength":""} name="searchinp"  onChange={(e) => { this.inputchange(e); this.searchapi(e.target.value) }} type="text" value={this.state.searchinp} />
                                </div> 
                                {/* : ''} */}
                            {this.state.showinp && this.state.searchres&&this.state.searchaaray && this.state.searchinp.length > 1 ?
                                <div className="search-res">
                                    {this.state.searchaaray.length !== 0 && this.state.searchres.length!==0?
                                        <ul>
                                            {this.state.searchaaray.map((cat, index) => {
                                                return <li key={index}>
                                                    <Link to={`/category/${this.state.catarray[index]}/${this.state.catuuid[index]}`}>
                                                        {cat.replace(/&amp;/g,'&') + " in "}
                                                        <span>
                                                            {this.state.catarray[index].replace(/&amp;/g, '&')}
                                                        </span>
                                                    </Link>
                                                </li>
                                            })}
                                        </ul>
                                        :
                                        <ul className="noresult">
                                            <li>No Results</li>
                                        </ul>
                                    }

                                </div> : ''}
                            <img onClick={() => this.showinp()} src={search} alt='search' />
                        </div>


                        <div onClick={() => this.setState({ quote: !this.state.quote })} className="headquote hvr-shrink">
                            Get a Quote
                        </div>
                    </div>
                </div>
                </div>
                            :""}


                {this.state.quote ?
                    <div style={{zIndex:this.state.quote?'':'-100'}} onClick={() => this.setState({ quote: !this.state.quote })} className="quote-form-bg">
                    </div>
                    : ''}
                {this.state.quote ?
                    <div className={this.state.quote ?"quote-form slideDown":"quote-form"}>
                        <div className="qoute-form-sec">
                        <img  onClick={() => this.setState({ quote: !this.state.quote })} src={mobclose} alt="close" />

                            <div className="quote-heading">
                                <h2>Get a quote</h2>
                            </div>
                            <div className="name-mail">
                                <input type="text" placeholder="Name" onChange={(e) => { this.inputchange(e) }} name="qname" value={this.state.qname} />
                                <input type="email" placeholder="Email" onChange={(e) => { this.inputchange(e) }} name="qemail" value={this.state.qemail} />
                                <input type="number" placeholder="Phone" onChange={(e) => { this.inputchange(e) }} name="qnum" value={this.state.qnum} />
                            </div>
                            <div className="quote-sel-box">
                                <select className="cc_code" onChange={e => this.inputchange(e)} name="area"  >
                                <option disabled selected value> Section</option>
                                    {this.state.sections.map((cc, id) => {
                                        return (
                                            <option key={id} value={cc}>{cc}</option>
                                        )
                                    })}
                                </select>
                                <select className="cc_code" onChange={e => this.inputchange(e)} name="sectn"  >
                                <option disabled selected value> Store Area</option>
                                    {this.state.storearea.map((cc, id) => {
                                        return (
                                            <option key={id} value={cc}>{cc}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="quote-req">
                                <textarea placeholder="Requirement" onChange={(e) => { this.inputchange(e) }} name="qmsg" value={this.state.qmsg} />
                            </div>
                            <div className="quote-btn">
                                <button onClick={() => this.formsubmit()}>{this.state.btntext}</button>
                            </div>
                        </div>
                        {/* <div className="qoute-footer"></div> */}
                    </div>
                    : ''}
                {this.state.mobmenu ?
                    <div onClick={() => this.setState({ mobmenu: !this.state.mobmenu })} className="bgblack">
                    </div> : ''}
                {this.state.mobmenu ?
                    <ul className="mobmenu">
                        <div className="mobclose">
                            <img src={redsearch} alt="redsearch" />
                            <input name="searchinp" onChange={(e) => { this.inputchange(e); this.searchapi(e.target.value) }} onFocus={() => this.setState({ inpfocus: true })} onBlur={() => this.setState({ inpfocus: false })} value={this.state.searchinp} />
                            <img style={{left:0}} onClick={() => this.setState({ mobmenu: !this.state.mobmenu })} src={mobclose} alt="close" />
                        </div>
                        {/* {this.state.inpfocus || this.state.searchinp !== '' ?
                            <div className="noresults">
                                No results found
                            </div>
                            : ''} */}
                        {(this.state.inpfocus || this.state.searchinp !== '') && this.state.searchinp.length > 1&&this.state.searchres&&this.state.searchaaray  ?
                            this.state.searchres ?
                                <div className="mobresults">
                                    {this.state.searchaaray.length !== 0  && this.state.searchres.length!==0?
                                        <ul>
                                            {this.state.searchaaray.map((cat, index) => {
                                                return <li key={index}>
                                                    <Link to={`/category/${this.state.catarray[index]}/${this.state.catuuid[index]}`}>
                                                        {cat.replace(/&amp;/g,'&') + " in "}
                                                        <span>
                                                            {this.state.catarray[index].replace(/&amp;/g, '&')}
                                                        </span>
                                                    </Link>
                                                </li>
                                            })}
                                        </ul>
                                        :
                                        this.state.searchinp.length > 1 ?
                                            <ul className="noresult">
                                                <li>No Results</li>
                                            </ul> : ''
                                    }
                                </div>
                                : '' : ''}
                        {!this.state.inpfocus ?
                        this.state.searchaaray.length<1?
                            <div className="topdivn-sec">
                                <div className="topdivn">
                                    <Link to='/division/trading-division/27fca701-5bee-46d1-b49b-7d57fde95975'>
                                        Trading Division
                                </Link>
                                    <Link to='/division/project-division/4de860d3-23ca-405e-bc2d-03da1d3ac325'>
                                        Projects Division
                                </Link>
                                    <Link to='/division/switchgears-divisions/9c6414e6-ecd4-414c-b05a-fd5a44adfa1f'>
                                        Switchgears Division
                                </Link>
                                </div>
                                <div onClick={() => this.setState({ quote: !this.state.quote })} className="headquote">
                                    Get a Quote
                                </div>
                            </div>
                           :'' : ''}
                        {!this.state.inpfocus ?
                            this.state.searchaaray.length<1?
                            <div className="mobcats">
                                {this.state.cats ?
                                    this.state.cats.map((cat, index) => {
                                        return <Link key={index} to={`/category/${cat.attributes.name}/${cat.attributes.uuid}`}>
                                            {cat.attributes.name}
                                        </Link>
                                    })
                                    : ''}
                            </div>
                          :''  : ''}

                    </ul> : ''}
                <div className="retopsec contain">
                    <div className="relogo">
                        <Link to="/">
                            <img src={logo} alt="gulftechlogo" />
                        </Link>
                    </div>
                    <div onClick={() => this.setState({ mobmenu: !this.state.mobmenu })} className="mob-ham">
                        <img src={ham} alt="ham" />
                    </div>
                    <div className="reright">
                        <div className="headdiv">
                            <Link to='/division/trading-division/27fca701-5bee-46d1-b49b-7d57fde95975'>
                                Trading Division
                            </Link>
                            <Link to='/division/project-division/4de860d3-23ca-405e-bc2d-03da1d3ac325'>
                                Projects Division
                            </Link>
                            <Link to='/division/switchgears-divisions/9c6414e6-ecd4-414c-b05a-fd5a44adfa1f'>
                                Switchgears Division
                            </Link>
                        </div>
                        <div className="search-section">
                            {/* {this.state.showinp ? */}
                                <div className="search-bar" >
                                    <input className={this.state.showinp?"inputlength":""} name="searchinp"  onChange={(e) => { this.inputchange(e); this.searchapi(e.target.value) }} type="text" value={this.state.searchinp} />
                                </div> 
                                {/* : ''} */}
                            {this.state.showinp && this.state.searchres&&this.state.searchaaray && this.state.searchinp.length > 1 ?
                                <div className="search-res">
                                    {this.state.searchaaray.length !== 0 && this.state.searchres.length!==0?
                                        <ul>
                                            {this.state.searchaaray.map((cat, index) => {
                                                return <li key={index}>
                                                    <Link to={`/category/${this.state.catarray[index]}/${this.state.catuuid[index]}`}>
                                                        {cat.replace(/&amp;/g,'&') + " in "}
                                                        <span>
                                                            {this.state.catarray[index].replace(/&amp;/g, '&')}
                                                        </span>
                                                    </Link>
                                                </li>
                                            })}
                                        </ul>
                                        :
                                        <ul className="noresult">
                                            <li>No Results</li>
                                        </ul>
                                    }

                                </div> : ''}
                            <img onClick={() => this.showinp()} src={search} alt='search' />
                        </div>


                        <div onClick={() => this.setState({ quote: !this.state.quote })} className="headquote hvr-shrink">
                            Get a Quote
                        </div>
                    </div>
                </div>
                {/* RED section */}
                <div className="reredsec contain">
                    {this.state.cats ?
                        this.state.cats.map((cat, index) => {
                            return <Link key={index} to={`/category/${cat.attributes.name}/${cat.attributes.uuid}`}>
                                {cat.attributes.name}
                            </Link>
                        })
                        : ''}
                </div>
            </div>
        );
    }
}

export default Header;