import React, { Component } from 'react';
import error from '../img/404.jpg';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";


class Error404 extends Component {

  render() {
    return (
         <div class="container">

                <div class="row">
                    <div class="col-sm-12 error-section-404">
                        <img src={error} alt="error" class="error-img-404 img-responsive"/>
                        <h3>LOOKS LIKE YOU'RE LOST</h3>
                        <p class="error-para">You don't have permission to access this page. </p>
                        <h3 class="error-head cursor"> <Link to="/"> GO TO HOME</Link> </h3>
                    </div>
                </div>   
    </div>
    );
  }
}

export default Error404;