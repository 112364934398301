import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css"; 
import "../../node_modules/slick-carousel/slick/slick-theme.css"
class Updates2 extends Component {
    constructor(props){
        super(props);
        console.log(props,"deveeni")
        this.state={
            data:props.data,
            divisionupdate:props.divisionupdate,
            newsupdate:props.newsupdate
        }
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            data:nextProps.data,
            divisionupdate:nextProps.divisionupdate,
            newsupdate:nextProps.newsupdate
        })
    }
    render() {
        const settings = {
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          beforeChange: function(currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
          },
          afterChange: function(currentSlide) {
            console.log("after change", currentSlide);
          }
        };
        return (
            <div>
        <h2>Vertical Mode</h2>
        <Slider {...settings}>
          <div>
            <h3>1</h3>
          </div>
          <div>
            <h3>2</h3>
          </div>
          <div>
            <h3>3</h3>
          </div>
          <div>
            <h3>4</h3>
          </div>
          <div>
            <h3>5</h3>
          </div>
          <div>
            <h3>6</h3>
          </div>
        </Slider>
      </div>
        );
    }
}

export default Updates2;