import React, { Component } from 'react';
import Video from './Video';
import play from '../img/Play video.png';
import trading from '../img/trading.png';
import { baseurl } from "./API";
import Slide from 'react-reveal/Slide';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playtrue: false,
            imgres: trading,
            slide: false,
            popup: false,
            popup_vid: false,
            autoplay: "0",
            overflow: false,
            api: '',
            vidurl:props.url,
            bannerimg:`${baseurl+props.bannerimg}`
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            vidurl:nextProps.url,
            playtrue:false,
            bannerimg:`${baseurl+nextProps.bannerimg}`
        })
    }

    

    popupchange() {
        this.setState({
            popup: !this.state.popup,
            overflow: !this.state.overflow

        })
        document.body.classList.add(this.state.overflow ? "add" : "home");
        document.body.classList.remove(this.state.overflow ? "home" : "add");
        document.getElementById("top").classList.add(this.state.overflow ? "add" : "top");
        document.getElementById("middle").classList.add(this.state.overflow ? "add" : "middle");
        document.getElementById("bottom").classList.add(this.state.overflow ? "add" : "bottom");
        document.getElementById("top").classList.remove(this.state.overflow ? "top" : "add");
        document.getElementById("middle").classList.remove(this.state.overflow ? "middle" : "add");
        document.getElementById("bottom").classList.remove(this.state.overflow ? "bottom" : "add");


    }

    popupvid() {
        this.setState({
            slide: true
        })

        setTimeout(() => {

            this.setState({
                slide: false
            })

        }, 800)
        setTimeout(() => {
            this.setState({
                popup_vid: !this.state.popup_vid,
                autoplay: "1",
                overflow: !this.state.overflow
            })
        }, 800)
        document.body.classList.add(this.state.overflow ? "add" : "home");
        document.body.classList.remove(this.state.overflow ? "home" : "add");
    }
    over() {
        this.setState({
            overflow: !this.state.overflow,
            popup: !this.state.popup

        })

        document.body.classList.add(this.state.overflow ? "add" : "home");
        document.body.classList.remove(this.state.overflow ? "home" : "add");
        document.getElementById("top").classList.add(this.state.overflow ? "add" : "top");
        document.getElementById("middle").classList.add(this.state.overflow ? "add" : "middle");
        document.getElementById("bottom").classList.add(this.state.overflow ? "add" : "bottom");
        document.getElementById("top").classList.remove(this.state.overflow ? "top" : "add");
        document.getElementById("middle").classList.remove(this.state.overflow ? "middle" : "add");
        document.getElementById("bottom").classList.remove(this.state.overflow ? "bottom" : "add");
    }

    autodisable() {
        this.setState({
            autoplay: "0"
        })
    }

    // componentWillMount() {


    //     Axios(baseurl + '/jsonapi/block_content/basic', {
    //         method: 'GET',
    //         headers: {
    //         }
    //     })
    //         .then((res) => {
    //             this.setState({
    //                 api: res.data
    //             })
    //             console.log(res.data.data[5].attributes.field_link.uri)

    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
        
    // }

    playtrue() {
        this.setState({
            playtrue: true
        })
    }

    render() {
        return (
            <div >
                <header style={{ position: "relative", overflow: 'hidden' }} className="App-header">
                    <div className="video-img-tint"></div>
                    <Video url={this.state.vidurl} play={this.state.playtrue} vidsrc={this.props.vidsrc} />
                    {
                        !this.state.playtrue ? <img id="overlayimg" src={this.state.bannerimg} /> : ''
                    }
                    {
                        !this.state.playtrue ?
                        <Slide top>
                            <div  onClick={() => this.playtrue()} id="overlayplay">
                                <img  src={play} />
                                <p>Play Video</p>
                            </div>
                            </Slide>
                            : ''
                    }
                </header>
            </div>
        );
    }
}

export default Navbar;


