import React, { Component } from 'react';
import Axios from 'axios';
import { baseurl } from './API'
import ReactHtmlParser from 'react-html-parser';
import Header from './header';
import dummy from '../img/subdummy.jpg'
import Footerindex from './Footerindex';
import PGallery from './PGallery';
import Slides from './Slides';
import SecondFooter from './secondFooter';

class Category extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uuid: props.match.params.uuid,
            name: props.match.params.name,
            sec: 1,
            logoarray: []

        }
    }
    componentWillReceiveProps(nextProps) {
        this.datacall(nextProps.match.params.uuid)
        this.setState({
            name: nextProps.match.params.name,
            uuid: nextProps.match.params.uuid
        })
    }

    subcategory(uuid) {
        Axios(baseurl + '/jsonapi/taxonomy_term/sub_category?include=field_thumbnail&filter[idFilter][condition][path]=field_category.uuid&filter[idFilter][condition][value]=' + uuid, {
            method: 'GET',
            headers: {
            }
        })
            .then((data) => {
                console.log(data, 'devss')
                this.setState({
                    subcategory: data.data
                })
                let i, j, logoarray = []
                for (i = 0; i < data.data.data.length; i++) {
                    if (data.data.data[i].relationships.field_thumbnail.data !== null) {
                        for (j = 0; j < data.data.included.length; j++) {
                            if (data.data.data[i].relationships.field_thumbnail.data.id === data.data.included[j].id) {
                                logoarray.push(baseurl + data.data.included[j].attributes.uri.url)

                            }
                        }
                    }
                    else {
                        logoarray.push(null)
                    }

                }
                this.setState({
                    logoarray: logoarray
                })
                console.log(logoarray, "zzz")
                console.log(data.data, 'newws')
                this.statsblock()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    datacall(uuid) {
        Axios(baseurl + '/jsonapi/taxonomy_term/category?include=field_category_icon&filter[uuid]=' + uuid, {
            method: 'GET',
            headers: {
            }
        })
            .then((res) => {
                this.setState({
                    api: res.data
                })
                this.subcategory(uuid)
                console.log(res.data, 'wws')

            })
            .catch((err) => {
                console.log(err)
            })
    }

    componentDidMount() {
        this.datacall(this.state.uuid)
        this.topFunction()
    }

    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    render() {
        return (
            <div className="cat_pag">
                <Header cont={this.props.tab} />
                <div className="rednews contain">
                    <div className="rednews-head">
                        {this.state.name.replace(/&amp;/g, '&')}
                    </div>
                    <div id="#rednews" className="rednews-div">
                        {this.state.api ?
                            this.state.api.data[0].attributes.description ?
                                <div className="cat-desc">
                                    {ReactHtmlParser(this.state.api ? this.state.api.data[0].attributes.description.value : '')}
                                </div>
                                : '' : ''}
                    </div>
                </div>
                {this.state.subcategory && this.state.logoarray.length !== 0 ?
                    <div className="subthumbs contain">
                        {this.state.logoarray.map((sub, index) => {
                            if(this.state.subcategory.data[index])
                            return (
                                <div className="mainthumb">
                                    <div style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.78), rgba(230, 222, 222, 0)), url(${sub?sub:dummy})` }}></div>
                                    <p>{this.state.subcategory.data[index].attributes.name}</p>
                                </div>
                            )
                        })}
                    </div>
                    : ''}
                <div className="red-cat-slides">
                    <Slides path={"field_category"} uuid={this.state.uuid} />
                </div>
                <div className="red-gallery">
                    <PGallery path={"field_category"} uuid={this.state.uuid} />
                </div>
                <Footerindex info={"Contact Us - Home Page"} />
                <SecondFooter/>
            </div>
        );
    }
}

export default Category;