import React, { Component } from 'react';
import logo from '../img/Logo.png';
import { Link } from "react-router-dom";
import social1 from '../img/fb(1).png'
import social2 from '../img/fb(2).png'
import social3 from '../img/fb(3).png'

class SecondFooter extends Component {

  
    render() {
        return (

            <div class="sec-footer">
                <div className="relogo">
                        <Link to="/">
                            <img src={logo} alt="gulftechlogo" />
                        </Link>
                    </div>
                    <div className="soc-foot">
                    <div className="headdiv">
                            <Link to='/division/trading-division/27fca701-5bee-46d1-b49b-7d57fde95975'>
                                Trading Division
                            </Link>
                            <Link to='/division/project-division/4de860d3-23ca-405e-bc2d-03da1d3ac325'>
                                Projects Division
                            </Link>
                            <Link to='/division/switchgears-divisions/9c6414e6-ecd4-414c-b05a-fd5a44adfa1f'>
                                Switchgears Division
                            </Link>
                        </div>
                        <div className="social">
                            <a target='_blank' href="https://www.facebook.com/gulftechgroup/">
                                <img src={social1} alt='fb'/>
                            </a>
                            <a target='_blank' href="https://www.instagram.com/gulftech_group/">
                                <img src={social2} alt='fb'/>
                            </a>
                            <a target='_blank' href="https://www.youtube.com/channel/UC4Q_sPuSVmARiF9QAd2K79Q?pbjreload=10">
                                <img src={social3} alt='fb'/>
                            </a>
                        </div> 
                    </div>                                       
            </div>
        );
    }
}

export default SecondFooter;

