import React from 'react'
import { baseurl } from './API'

const Stats = (props) => (
    props.stat.data.map((stat,index)=>{
    return<div className="statsec">
        <div className="statshead">
            <div className="staticon">
                <img src={baseurl+props.stat.included[index].attributes.uri.url} alt="sats" />
            </div>
            <div className="stattext">
                <div className="stathead">
                    {stat.attributes.field_value}
                </div>
                <div className="statsub">
                {stat.attributes.info}
            </div>
            </div>
        </div>
    </div>
    })

)

export default Stats